<template>
	<div id="account_manage">
		<jy-query ref="form" :model="form">
			<jy-query-item prop="title" label="标题:" label-width="70px">
				<el-input placeholder="请输入" v-model="form.title"></el-input>
			</jy-query-item>
			<jy-query-item prop="pubFlag" label="发布状态:">
				<el-select v-model="form.pubFlag" placeholder="请选择">
					<el-option v-for="item in accountStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<!-- <jy-query-item prop="createTime" label="创建时间:" label-width="70px">
        <el-input placeholder="请输入" v-model="form.createTime"></el-input>
      </jy-query-item> -->
			<template slot="search">
				<!-- <el-button type="primary" @click="oncheck"
          v-if="btnexist('messageNewsSearch')">查询</el-button>
        <el-button type="primary" @click="resetForm('form')"
          v-if="btnexist('messageNewsReset')">重置</el-button> -->
				<el-button type="primary" @click="oncheck" v-if="btnexist('scheduleMessageNewsList')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')" v-if="btnexist('scheduleMessageNewsReset')">重置</el-button>
				<!-- <el-row>
        </el-row> -->
			</template>
			<template slot="operate">
				<!-- <el-button type="primary" v-on:click="addUdate()" v-if="btnexist('messageNewsAdd')">新增</el-button> -->
				<el-button type="primary" v-on:click="addUdate()" v-if="btnexist('scheduleMessageNewsInsert')">新增</el-button>
			</template>
		</jy-query>
		<jy-table max-height="595" ref="jyTable" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<!-- <jy-table-column type="selection" width="40"></jy-table-column> -->
			<jy-table-column prop="title" label="标题" min-width="260"></jy-table-column>
			<jy-table-column prop="pubUserName" label="发布人" width="120"></jy-table-column>
			<jy-table-column prop="orgName" label="发布机构" min-width="120"></jy-table-column>
			<!-- <jy-table-column prop="desc" label="公告内容" min-width="200"></jy-table-column> -->
			<jy-table-column label="发布状态" width="100">
				<template slot-scope="scope">{{ scope.row.pubFlag ? "已发布" : "未发布" }}</template>
			</jy-table-column>
			<jy-table-column prop="createTime" label="创建时间" width="160"></jy-table-column>
			<!-- <jy-table-column prop="pubTime" label="发布日期" min-width="160"></jy-table-column> -->
			<jy-operate v-bind:list="operateList" width="300"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<dialog-edit-news-form ref="dialogEditNewsFormRef" @updateList="getList"></dialog-edit-news-form>
		<dialog-news-info ref="dialogNewsInfo"></dialog-news-info>
	</div>
</template>
<script>
import DialogEditNewsForm from "@/components/pages/admin/message/news/editNewsForm.vue";
import DialogNewsInfo from "@/components/pages/admin/message/news/dialogNewsInfo.vue";
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			// 搜索发布状态options列表
			accountStatusList: [
				{
					label: "全部",
					value: ""
				},
				{
					label: "已发布",
					value: 1
				},
				{
					label: "未发布",
					value: 0
				}
			],
			// 搜索表单
			form: {
				title: "",
				pubFlag: "",
				releaseDate: ""
			},
			// 翻页数据
			pageSize: 10,
			total: 0,
			pageIndex: 1,
			// 表格数据
			dataList: [],
			operateList: [],
			// 批量删除
			batchdeleteList: [],

			btnMenuId: "scheduleMessageNews" // 会调取该页面下按钮权限
		};
	},
	mixins: [btnMixins],
	components: {
		DialogEditNewsForm,
		DialogNewsInfo
	},
	created() {
		this.getList();
		this.setOperateList();
	},
	filters: {
		state(val) {
			if (val == 1) {
				return "已发布";
			} else {
				return "未发布";
			}
		}
	},
	methods: {
		setOperateList() {
			let l = [
				{
					name: "详情",
					icon: "el-icon-edit-view",
					fun: this.handleInfo,
					isShow: () => {
						if (this.btnexist("scheduleMessageNewsDetail")) {
							return true;
						} else {
							return false;
						}
					}
				},
				{
					name: "修改",
					icon: "el-icon-edit-outline",
					fun: this.addUdate,
					isShow: row => {
						if (row.pubFlag != 1 && this.btnexist("scheduleMessageNewsUpdate")) {
							return true;
						} else {
							return false;
						}
					}
				},
				{
					name: "删除",
					icon: "el-icon-delete",
					fun: this.handleDelete,
					isShow: row => {
						return row.pubFlag != 1 && this.btnexist("scheduleMessageNewsDelete");
					}
				},
				{
					name: "发布",
					icon: "el-icon-view",
					fun: this.release,
					isShow: row => {
						if (row.pubFlag != 1 && this.btnexist("scheduleMessageNewsRelease")) {
							return true;
						} else {
							return false;
						}
					}
				},
				{
					name: "撤销发布",
					icon: "el-icon-view",
					fun: this.release,
					isShow: row => {
						if (row.pubFlag == 1 && this.btnexist("scheduleMessageNewsUnrelease")) {
							return true;
						} else {
							return false;
						}
					}
				}
			];
			this.operateList = l;
		},
		// 获取表格列表数据
		getList() {
			let option = {
				...this.form,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/message/news/queryNewsPage";
			this.$http.post(url, option, { baseType: "base" }).then(data => {
				this.dataList = data.records ?? [];
				this.total = data.total;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 批量选择
		selectchange(selection) {
			// console.log(selection)
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			// console.log(selection)
			this.batchdeleteList = selection;
		},
		// 删除
		handleDelete(row) {
			this.$confirm("是否删除该公告?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = { id: row.id, delFlag: 1 };
					let url = "/message/news/updNews";
					this.$http.post(url, option, { baseType: "base", isRequestParam: false }).then(res => {
						this.$message({
							type: "success",
							message: res.msg || "删除成功"
						});
						this.getList();
					});
				})
				.catch(() => {
					// this.$message({
					//   type: 'info',
					//   message: '已取消删除',
					// })
				});
		},
		addUdate(option) {
			if (option) {
				this.$refs.dialogEditNewsFormRef.init(option, "update");
			} else {
				this.$refs.dialogEditNewsFormRef.init();
			}
		},
		release(row) {
			this.$confirm(`是否${row.pubFlag ? "撤销" : ""}发布该公告?`, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = { id: row.id, pubFlag: row.pubFlag ^ 1 };
					let url = "/message/news/pubNews";
					this.$http.post(url, option, { baseType: "base", isRequestParam: false }).then(res => {
						// console.log(res)
						this.$message({
							type: "success",
							message: res.msg || "发布状态修改成功"
						});
						this.getList();
					});
				})
				.catch(() => {});
		},
		handleInfo(row) {
			this.$refs.dialogNewsInfo.init(row);
		}
	}
};
</script>
<style scoped="accountManagement" lang="scss"></style>
