<!-- dialog - 新闻详情 -->
<template>
  <jy-dialog
    title="消息公告"
    class=""
    :visible.sync="dialogVisible"
    width="90%"
    :before-close="handleClose">
    <div class="content-info">
        <el-row>
          <el-col class="cont-item">
            <span class="label">标题:</span>
            <span>{{ newsInfo.title || '' }}</span>
          </el-col>
        </el-row>
        <el-row>
          <!-- <el-col :span="8" class="cont-item">
            <span class="label">模块:</span>
            <span>{{ ['','公告','新闻'][newsInfo.model] || '' }}</span>
          </el-col> -->
          <el-col :span="8" class="cont-item">
            <span class="label">类型:</span>
            <span>{{ newsInfo.typeName || '' }}</span>
          </el-col>
          <el-col :span="8" class="cont-item">
            <span class="label">发布人:</span>
            <span>{{ newsInfo.pubUserName || '' }}</span>
          </el-col>
          <el-col :span="8" class="cont-item">
            <span class="label">所属机构:</span>
            <span>{{ newsInfo.orgName || '' }}</span>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col class="cont-item">
            <span class="label">简介:</span>
            <span class="cont">{{ newsInfo.desc || '' }}</span>
          </el-col>
        </el-row> -->
        <el-row class="content-row">
          <el-col v-html="newsInfo.newsContextList?.[0]?.context || ''">
          </el-col>
        </el-row>
    </div>
    <!-- footer -->
    <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
    </div>
  </jy-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      newsInfo: {
        desc: undefined, // 简介 --
        hotFlag: 0, // 0 普通  1 热门
        id: undefined,
        model: 1, // 模块分类 1 公告  2 新闻 --
        title: undefined, // 新闻标题 --
        typeId: undefined, // 新闻类型ID --
        typeName: undefined, // 新闻类型名称
        systemType: 0, // 0 all 1 android 2 ios --
        picUrl: undefined, // 图片地址
        appCodeList: ["TLF_PALM_BUS"],
        // 新闻内容集合(因为是富文本固定传单项数组)
        newsContextList: [{
          contextType: 4, // 新闻内容类型 1文字 2图片url 3图片字节流 4html
          context: '', // 新闻内容
          sequence: 0, // 新闻内容排序
        }],
      }
    }
  },
  methods: {
    /** comp - 打开 */
    init (params) {
      this.$http.post('/message/news/detailNews', {id: params.id}, {baseType: 'base', isRequestParam: false}).then(res => {
          this.newsInfo = res
          this.dialogVisible = true
        }).catch(() => {
          this.$message({
            type: 'error',
            title: '获取详情失败'
          })
        })
    },
    /** comp - 关闭 */
    handleClose () {
      this.dialogVisible = false
    }
    /** */
  }
}
</script>

<style lang="scss" scoped>
.content-info {
  line-height: 30px;
  .cont-item {
    display: flex;
  }
  .label {
    display: inline-block;
    width: 100px;
    text-align: right;
    margin-right: 3px;
  }
  .cont {
    display: inline-block;
    flex: 1;
  }
  .content-row {
    border-top: 1px solid #F0F0F0;
    margin: 10px 0 10px 60px;
    padding-top: 10px;
  }
}
</style>