<!-- 消息管理 - 公告发布 -->
<template>
  <jy-dialog
    title="消息公告"
    class=""
    :visible.sync="dialogVisible"
    width="90%"
    :before-close="handleClose">
    <div>
        <el-form :model="diaform" ref="dialogForm" :rules="rules" :label-width="formLabelWidth">
            <el-form-item label="标题:" prop="title">
                <el-input v-model="diaform.title" placeholder="请输入"></el-input>
            </el-form-item>
            <el-row>
              <!-- <el-col :span="8">
                <el-form-item label="模块类型:" prop="model">
                    <el-select v-model="diaform.model" placeholder="请选择">
                      <el-option v-for="item in modelOptions" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="新闻类型:" prop="typeId">
                    <el-select v-model="diaform.typeId" placeholder="请选择" @change="handleTypeChange">
                      <el-option v-for="item in typeDicts" :key="item.id" :label="item.typeName"
                        :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col span="8">
                <el-form-item label="是否热门:" prop="hotFlag">
                    <el-select v-model="diaform.hotFlag" placeholder="请选择">
                      <el-option v-for="item in hotFlagOptions" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="发布人:" prop="typeId">{{ diaform.pubUserName ?? '' }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="所属机构:" prop="typeId">{{ diaform.orgName ?? '' }}</el-form-item>
              </el-col>
            </el-row>
            <!-- <el-form-item label="简介:" prop="content">
                <el-input v-model="diaform.desc" type="textarea" :rows="2" placeholder="简介"></el-input>
            </el-form-item> -->
            <el-form-item label="内容:" prop="content">
                <FormItemEdit v-model="diaform.newsContextList[0].context" />
            </el-form-item>
        </el-form>
    </div>
    <!-- footer -->
    <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSave">确 定</el-button>
    </div>
  </jy-dialog>
</template>

<script>
import FormItemEdit from '@/components/wangEdit/editFormItem.vue'

export default {
  components: { FormItemEdit },
  data () {
    return {
      dialogVisible: false, // comp - 显示
      formLabelWidth: '100px',
      // form - 表单数据
      diaform: {
        desc: undefined, // 简介 --
        hotFlag: 0, // 0 普通  1 热门
        id: undefined,
        model: 1, // 模块分类 1 公告  2 新闻 --
        title: undefined, // 新闻标题 --
        typeId: undefined, // 新闻类型ID --
        typeName: undefined, // 新闻类型名称
        systemType: 0, // 0 all 1 android 2 ios --
        picUrl: undefined, // 图片地址
        // pubUserId: this.$store.state?.info?.userId,
        pubUserName: this.$store.state?.info?.userNa,
        orgId: this.$store.state?.info?.orgId,
        orgName: localStorage.getItem('orgNa'),
        appCodeList: ["TLF_PALM_BUS"],
        // 新闻内容集合(因为是富文本固定传单项数组)
        newsContextList: [{
          contextType: 4, // 新闻内容类型 1文字 2图片url 3图片字节流 4html
          context: '', // 新闻内容
          sequence: 0, // 新闻内容排序
        }],
      },
      // form - rules
      rules: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          }
        ],
      },
      // options - 热门
      hotFlagOptions: [
        {
          label: '普通',
          value: 0
        },
        {
          label: '热门',
          value: 1
        },
      ],
      // options - 模块分类
      modelOptions: [
        {
          label: '公告',
          value: 1
        },
        {
          label: '新闻',
          value: 2
        },
      ],
      // options - 新闻类型(远程字典)
      typeDicts: [
        {
          "id": 2,
          "typeName": "公告咨询"
        },
        {
          "id": 4,
          "typeName": "失物招领"
        },
      ]
    }
  },
  methods: {
    /** comp - 初始化 */
    init (params) {
      console.log('编辑:', params)
      console.log('=======', this.$store.state)
      if(params) {
        this.$http.post('/message/news/detailNews', {id: params.id}, {baseType: 'base', isRequestParam: false}).then(res => {
          this.diaform = this.dataToForm(res)
          this.dialogVisible = true
          // this.getTypeList()
        }).catch(() => {
          this.$message({
            type: 'error',
            message: '获取详情失败'
          })
        })
      } else {
        this.diaform = this.dataToForm()
        this.dialogVisible = true
        // this.getTypeList()
      }
    },
    /** comp - 关闭 */
    handleClose () {
      this.dialogVisible = false
    },
    /** comp - save */
    handleSave () {
      console.log('save', this.diaform)
      // 新增
      if(!this.diaform.id) {
        this.$http.post('/message/news/addNews', this.diaform, {baseType: 'base', isRequestParam: false}).then(() => {
          this.$message({
            message: '提交成功',
            type: 'success'
          })
          this.handleClose()
          this.$emit('updateList')
        })
      } else {
        // 修改
        this.$http.post('/message/news/updNews', this.diaform, {baseType: 'base', isRequestParam: false}).then(() => {
          this.$message({
            message: '提交成功',
            type: 'success'
          })
          this.handleClose()
          this.$emit('updateList')
        })
      }
    },
    // --------------------------- 新闻类型
    /** options - 新闻类型字典 */
    // getTypeList () {
    //   this.$http.post('/message/news/queryNewsTypePage', {isPage: 0}, {baseType: 'base'}).then(res => {
    //     this.typeDicts = res.records
    //   })
    // },
    /** change - 新闻类型修改时 */
    handleTypeChange (value) {
      if(!value) {
        this.diaform.typeName = ''
        return
      }
      this.diaform.typeName = this.typeDicts?.find(item => item.id === value)?.typeName
    },
    // --------------------------- 数据转换
    dataToForm (data) {
      let ret = {
        desc: undefined, // 简介 --
        hotFlag: 0, // 0 普通  1 热门
        id: undefined,
        model: 1, // 模块分类 1 公告  2 新闻 --
        title: undefined, // 新闻标题 --
        typeId: undefined, // 新闻类型ID --
        typeName: undefined, // 新闻类型名称
        systemType: 0, // 0 all 1 android 2 ios --
        picUrl: undefined, // 图片地址
        orgId: this.$store.state?.info?.orgId,
        orgName: localStorage.getItem('orgNa'),
        // pubUserId: this.$store.state?.info?.userId,
        pubUserName: this.$store.state?.info?.userNa,
        appCodeList: ["TLF_PALM_BUS"],
        // 新闻内容集合(因为是富文本固定传单项数组)
        newsContextList: [{
          contextType: 4, // 新闻内容类型 1文字 2图片url 3图片字节流 4html
          context: '', // 新闻内容
          sequence: 0, // 新闻内容排序
        }],
      }

      if(data) {
        ret = {
          appCodeList: data.appCodeList,
          desc: data.desc,
          hotFlag: data.hotFlag,
          id: data.id,
          model: data.model,
          picUrl: data.picUrl,
          pubFlag: data.pubFlag,
          orgId: data.orgId,
          orgName: data.orgName,
          // pubUserId: data.pubUserId,
          pubUserName: data.pubUserName ?? '',
          systemType: data.systemType,
          title: data.title,
          typeId: data.typeId,
          newsContextList: data.newsContextList.map((item, index) => {
            return {
              contenxtType: item.contenxtType || 4,
              context: item.context,
              sequence: item.sequence,
              titleId: item.titleId,
              id: item.id,
              delFlag: (index === 0)? 0 : 1,
            }
          }) || {
            contextType: 4, // 新闻内容类型 1文字 2图片url 3 图片字节流 4 html
            context: '', // 新闻内容
            sequence: 0, // 新闻内容排序
          }
        }
      }

      return ret
    },
  }
}
</script>